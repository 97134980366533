<template>
  <el-dialog
    title="花费订单趋势（近12小时）"
    :visible="show"
    width="800px"
    @close="close"
  >
    <div>
      <div>平均订单成本：{{ totalMsg.avgOrderCost }} {{ totalMsg.currency }}</div>
      <div
        ref="echatData"
        class="echatData"
        v-loading="loading"
      ></div>
    </div>
  </el-dialog>
</template>
<script>
import { dailySaleOrderTrend } from '@/api/multiPlatform';
export default {
  props: {
    show: Boolean,
    data: Object,
    level: String,
  },
  data() {
    this.chatOption = {
      color: ['#9A60B4', '#FAC858', '#f96f17', '#EE6666', '#3CA272', '#FC8452', '#9A60B4'],
      update: true,
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        show: true,
      },
      xAxis: {
        type: 'category',
        disableGrid: true,
        fontSize: 10,
      },
      yAxis: [
        {
          position: 'left',
          name: '花费',
          fontSize: 11,
          tofix: 1,
          gridType: 'dash',
          showTitle: true,
        },
        {
          position: 'right',
          // disabled:true,
          name: '订单量',
          textAlign: 'left',
          tofix: 1,
          fontSize: 11,
          gridType: 'dash',
          showTitle: true,
        },
      ],
      // },
      extra: {
        mix: {
          column: {
            width: 15,
            linearType: 'custom',
            seriesGap: 5,
            linearOpacity: 0.3,
            barBorderCircle: true,
            customColor: ['#9A60B4', '#FAC858'],
          },
          line: {
            type: 'curve',
            smooth: true,
            width: 2,
            linearOpacity: 0.3,
            activeType: 'hollow',
          },
        },
      },
    };
    return {
      totalMsg: {},
      loading: false,
    };
  },
  methods: {
    initChart() {
      let data = this.data.ad;
      let params = {
        platform: data.platform,
        accountId: data.adAccountId,
        campaignId: data.campaignId,
        credentialId: data.credentialId,
        level: this.level,
      };
      this.loading = true;
      dailySaleOrderTrend(params)
        .then((res) => {
          if (res.code == 0) {
            const myChat = this.$echarts.init(this.$refs.echatData);
            const option = {
              ...this.chatOption,
              xAxis: {
                ...this.chatOption.xAxis,
                data: res.data.hourList,
              },
              series: [
                {
                  name: '有效订单',
                  yAxisIndex: 1,
                  type: 'bar',
                  data: res.data.orderList,
                  itemStyle: {
                    color: '#9A60B4',
                    // new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //   { offset: 0, color: 'rgba(154,96,180,0.3)' },
                    //   { offset: 1, color: '#9A60B4' },
                    // ]),
                  },
                },
                {
                  name: '总订单',
                  yAxisIndex: 1,
                  type: 'bar',
                  data: res.data.totalOrderList,
                  itemStyle: {
                    color: '#FAC858',
                    // new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //   { offset: 0, color: 'rgba(154,96,180,0.3)' },
                    //   { offset: 1, color: '#9A60B4' },
                    // ]),
                  },
                },
                {
                  name: '花费',
                  type: 'line',
                  style: 'curve',
                  data: res.data.spendList,
                  color: '#FAC858',
                  smooth: true,
                },
                {
                  name: '成本',
                  type: 'line',
                  style: 'curve',
                  data: res.data.costList,
                  color: '#f96f17',
                  smooth: true,
                },
              ],
            };
            console.log(option);
            myChat.setOption(option, true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('update:show', false);
    },
  },
  watch: {
    show(bool) {
      if (bool) {
        this.initChart();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.echatData {
  width: 100%;
  height: 500px;
}
</style>
